import { submitFilter } from './submit'
import ClearTag from '../../../filter-common/clearTag'
import { setUrlParamsUsingData } from '../../../filter-common/urlDataParser'
import { deleteUrlParamValue, deleteUrlParam } from '../../../url-params/url-params'

const tagsParent = document.getElementById('filter_tags')
const filtersParent = document.getElementById('filter_form')
const clearTag = new ClearTag().render()

const addTag = (name, label, targetId, fieldType, rangeSlider = null) => {
    const tag = document.createElement('a')
    tag.className = 'filter-tag button button-filter-primary button-icon-cross'
    tag.innerText = label
    tag.title = label
    tag.setAttribute('data-target', targetId)
    tag.setAttribute('data-name', name)
    tag.addEventListener('click', () => {
        const targetElement = document.getElementById(targetId)

        if (fieldType === 'checkbox') {
            if (!targetElement.disabled) {
                tagsParent.removeChild(tag)
                targetElement.checked = false
                submitFilter()
                deleteUrlParamValue(`${name}[0]`, targetId)
            }
        } else if (fieldType === 'range') {
            tagsParent.removeChild(tag)
            const inputs = targetElement.querySelectorAll('input')
            let minMaxValues = []
            inputs.forEach((input) => {
                const currentInput = input
                currentInput.value = currentInput.dataset.value
                minMaxValues.push(currentInput.value)
            })
            rangeSlider.set(minMaxValues)
            deleteUrlParam(`${name}{}`)
            submitFilter()
        } else if (fieldType === 'radio') {
            if (!targetElement.disabled) {
                tagsParent.removeChild(tag)
                const inputs = targetElement.querySelectorAll('input')
                inputs.forEach((input) => {
                    const currentInput = input
                    currentInput.checked = false
                })
                deleteUrlParam(name)
                submitFilter()
            }
        }
    })
    tagsParent.appendChild(tag)
    tagsParent.appendChild(clearTag)
}

const removeTag = (tagId) => {
    const tagToRemove = tagsParent.querySelector(`[data-target='${tagId}']`)
    if (tagToRemove) {
        tagsParent.removeChild(tagToRemove)
    }
}

const toggleRangeTag = (parentId, rangeSlider) => {
    const parentElement = document.getElementById(parentId)
    const name = parentElement.querySelector('.fields').getAttribute('data-name')
    const fields = parentElement.querySelectorAll('input')
    const title = parentElement.querySelector('.title').innerText
    const units = parentElement.querySelector('.units-label').innerText
    const minValue = fields[0].value
    const maxValue = fields[1].value
    let tagLabel = `${title} ${minValue} - ${maxValue} ${units}`

    if (minValue === '' && maxValue !== '') {
        tagLabel = `${title} - ${window.globals.trans('filter.to')} ${maxValue} ${units}`
    } else if (minValue !== '' && maxValue === '') {
        tagLabel = `${title} - ${window.globals.trans('filter.from')} ${minValue} ${units}`
    }

    removeTag(parentId)
    if (minValue !== '' || maxValue !== '') {
        addTag(name, tagLabel, parentId, 'range', rangeSlider)
    }

    setUrlParamsUsingData('range', parentId)
    submitFilter()

    if (typeof ga == 'function') {
        // eslint-disable-next-line no-undef
        ga('send', {
            hitType: 'event',
            eventCategory: 'Filters',
            eventAction: title,
            eventLabel: `${minValue} - ${maxValue} ${units}`,
        })
    }
}

const toggleCheckboxTag = (parentId, tagId) => {
    const parentElement = document.getElementById(parentId)
    const name = parentElement.querySelector('.fields').getAttribute('data-name')
    const title = parentElement.querySelector('.title').innerText
    const field = filtersParent.querySelector(`#${CSS.escape(tagId)}`)
    const allCheckboxLabels = Array.from(filtersParent.querySelectorAll(`label[for='${tagId}']`))
    const labelsAsString = allCheckboxLabels
        .map((label) => {
            return label.innerText
        })
        .join(' - ')
    if (field.checked) {
        addTag(name, `${title} - ${labelsAsString}`, tagId, 'checkbox')
    } else {
        removeTag(tagId)
    }
    setUrlParamsUsingData('checkbox', parentId)
    submitFilter()

    if (typeof ga == 'function') {
        // eslint-disable-next-line no-undef
        ga('send', {
            hitType: 'event',
            eventCategory: 'Filters',
            eventAction: title,
            eventLabel: labelsAsString,
        })
    }
}

const toggleRadioTag = (parentId) => {
    const parentElement = document.getElementById(parentId)
    const name = parentElement.querySelector('.fields').getAttribute('data-name')
    const field = parentElement.querySelector('input:checked')
    const title = parentElement.querySelector('.title').innerText
    const fieldLabel = field.nextSibling.innerText
    const tagLabel = `${title} - ${fieldLabel}`

    removeTag(parentId)
    if (field.checked) {
        addTag(name, tagLabel, parentId, 'radio')
    }
    submitFilter()
    setUrlParamsUsingData('radio', parentId)

    if (typeof ga == 'function') {
        // eslint-disable-next-line no-undef
        ga('send', {
            hitType: 'event',
            eventCategory: 'Filters',
            eventAction: title,
            eventLabel: fieldLabel,
        })
    }
}

export { addTag, removeTag, toggleCheckboxTag, toggleRangeTag, toggleRadioTag }
