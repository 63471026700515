//
// Product view for swr - steel wire ropes
//

import { initGroupedVariantsTableSearch, initVariantsTableToggle } from '../../filter-common/filterExtended'
import scrollTo from '../../components/scrollTo/scrollTo'
import ropeEndings from '../custom-product-view/rope-endings'
import CartFormSWR from '../custom-product-view/cart-form-swr'
import Observer from '../../Observer/Observer'
import Util from '../../utils/utils'
;(($) => {
    $(() => {
        const module = $('.new-product-view.swr')

        if (!module.length) return

        const prepareSWRData = (data) => {
            data.columns = data.columns.filter((column) => column.key !== 'PartCode')
            return data
        }

        const swrObserver = new Observer()
        const attributesForm = $('#attributes-dropdowns')
        const ropeEndingsPlaceholder = $('#rope-endings')
        const cartFormSwrPlaceholder = $('#cart-form-swr')
        const stockModal = $('#stockModal')
        const stockDiv = $('.npp__stock--info')
        const showAllVariantsLink = $('.npp__show-all-variants')
        const showContactUsTab = $('.npp__show-contact-us')
        const showMoreButton = $('.show-more__button')
        const rawData = JSON.parse(attributesForm.attr('data-variants'))
        let keys = rawData.options.cartWidgetAttributes
        const data = prepareSWRData(rawData)
        const variantsTab = document.querySelector('#variants')
        const contactUsTab = document.querySelector('#contact-us')
        const productInformationTab = document.querySelector('#product-information')
        const allSubVariants = data.variants
        showMoreButton.click(() => goToAndOpenTab(productInformationTab))
        showAllVariantsLink.click(() => goToAndOpenTab(variantsTab))
        showContactUsTab.click(() => goToAndOpenTab(contactUsTab))

        const goToAndOpenTab = (target) => {
            if (target) {
                target.click()
                scrollTo(target, -160)
            }
        }

        const filtersDiv = document.getElementById('attributes-filters')
        const resultDiv = document.getElementById('attributes-results')

        const getAvailabilityLabel = (stockLevel, showUnits = false) => {
            let stockText = stockLevel > 0 ? `${window.globals.trans('filter.in_stock')}` : `${window.globals.trans('filter.out_of_stock')}`
            if (rawData.options.stockDisplay === 'absolute' && stockLevel > 0) {
                stockText += ` ${stockLevel}${showUnits ? ` ${window.globals.trans('filter.pieces')}` : ''}`
            }
            return stockText
        }
        const getAvailabilityIndicatorClass = (stockLevel) => (stockLevel > 0 ? 'stock in' : 'stock out')

        const generateStockInfo = (stockLevel) =>
            `<span class="stock-text">${getAvailabilityLabel(stockLevel)}</span> <span class="${getAvailabilityIndicatorClass(stockLevel)}"></span>`
        const generateStockWarehouseInfo = (stockLevel) =>
            `<span class="${getAvailabilityIndicatorClass(stockLevel)}"></span> <span class="stock-text">${getAvailabilityLabel(stockLevel, true)}</span>`

        const getStockLevel = (selectedVariant) => parseInt(selectedVariant.Stock)
        const refreshStock = (selectedVariant) => {
            const stockLevel = getStockLevel(selectedVariant)

            if (!isNaN(stockLevel)) {
                stockDiv.html(generateStockInfo(stockLevel))
            } else {
                stockDiv.html(
                    `<span class="stock-text"><a href="${attributesForm.attr('data-contact-url')}" class="ask" target="_blank">${window.globals.trans(
                        'filter.ask',
                    )}</a></span> <span class="stock no"></span>`,
                )
            }
        }
        const refreshStockModalData = (selectedVariant) => {
            const partCode = selectedVariant.PartCode
            const warehouses = selectedVariant.warehouses
            stockModal.find('.stock-modal__partcode').html(`<span class="bold">${window.globals.trans('product-page.product-number')}</span>: ${partCode}`)
            generateWarehousesTable(warehouses)
        }

        const generateWarehousesTable = (warehouses) => {
            const tableBody = document.querySelector('#warehousesTable tbody')

            if (warehouses && warehouses.length > 0) {
                const populateTable = (warehouses) => {
                    tableBody.innerHTML = warehouses
                        .map(
                            ({ name, stock }) => `
                <tr>
                    <td class="stock-modal__name">${name}</td>
                    <td class="stock-modal__stock">${generateStockWarehouseInfo(stock)}</td>
                </tr>
            `,
                        )
                        .join('')
                }
                populateTable(warehouses)
            } else {
                tableBody.innerHTML = `<tr><td colspan="2" class="text-center font-italic">${window.globals.trans(
                    'product-page.no-warehouses-message',
                )}</td></tr>`
            }
        }

        const getUniqueValues = (data, key) => {
            return [...new Set(data.map((item) => item[key]))]
        }

        const populateSelect = (selectElement, values) => {
            const selectedValue = $(selectElement).val()
            $(selectElement)
                .empty()
                .append(`<option disabled value="">${window.globals.trans('filter.select')}</option>`)
            values.forEach((value) => {
                const option = document.createElement('option')
                option.value = value
                option.textContent = value
                $(selectElement).append(option)
            })

            // Auto-select option if there is only one unique value
            if (values.length === 1) {
                $(selectElement).val(values[0])
            } else {
                $(selectElement).val(selectedValue) // Save selected item
            }

            // Selectric refresh
            $(selectElement).selectric('refresh')
        }

        const filterData = () => {
            let filteredData = allSubVariants

            keys.forEach((key) => {
                const selectElement = document.getElementById(key)
                const selectedValue = $(selectElement).val()

                if (selectedValue) {
                    filteredData = filteredData.filter((item) => item[key] === selectedValue)
                }
            })

            return filteredData
        }

        const updateFilters = () => {
            let filteredData = allSubVariants

            keys.forEach((key, index) => {
                const selectElement = document.getElementById(key)
                const selectedValue = $(selectElement).val()

                // Filter data based on current selection
                if (selectedValue) {
                    filteredData = filteredData.filter((item) => item[key] === selectedValue)
                }

                // Update subsequent selects only if they are not the current select
                if (index < keys.length - 1) {
                    const nextSelectElement = document.getElementById(keys[index + 1])
                    populateSelect(nextSelectElement, getUniqueValues(filteredData, keys[index + 1]))
                }
            })

            // send data to cart
            const finalResult = filterData()

            if (finalResult.length) {
                const variant = finalResult[0]
                resultDiv.parentElement.classList.remove('d-none')
                resultDiv.textContent = variant.PartCode

                const isBatchPrice = !!variant.BatchPrice
                const piecePrice = variant.Price ? variant.Price : null
                const batchPrice = variant.BatchPrice ? variant.BatchPrice : null
                refreshStock(variant)
                refreshStockModalData(variant)

                swrObserver.publish('activateCart', {
                    id: variant.id,
                    RopeDiameter: variant.RopeDiameter,
                    price: isBatchPrice ? batchPrice : piecePrice,
                    currencyCode: variant.currencyCode,
                })
            } else {
                resultDiv.parentElement.classList.add('d-none')
            }
        }

        const setSelectValuesById = (id) => {
            const selectedObject = allSubVariants.find((item) => item.id === Number(id))
            if (!selectedObject) {
                console.error(`Object with id ${id} not found.`)
                return
            }

            keys.forEach((key) => {
                const selectElement = document.getElementById(key)
                $(selectElement).val(selectedObject[key])
                // Refreshing Selectric
                $(selectElement).selectric('refresh')
            })
            // Update filters and display results
            updateFilters()
        }

        const selectFirstAvailableVariant = () => {
            const selectedItem = allSubVariants.find((item) => item.Stock && item.Stock > 0) || allSubVariants[0]
            setSelectValuesById(selectedItem.id)
        }

        const generateDropdowns = (keys, columns) => {
            filtersDiv.innerHTML = ''
            keys.forEach((key) => {
                const label = document.createElement('label')
                const labelText = columns.find((column) => column.key === key)?.label || key
                label.setAttribute('for', labelText)
                label.classList.add('br-to-space')
                label.innerHTML = `${labelText}: `

                const select = document.createElement('select')
                select.id = key
                select.name = key

                filtersDiv.appendChild(label)
                filtersDiv.appendChild(select)

                // Init Selectric
                $(select).selectric()
                // Handling selection changes in selects
                $(select).on('change', updateFilters)
                // Filling the select with unique values
                populateSelect(select, getUniqueValues(allSubVariants, key))
            })
        }

        const generateEndings = (observer, data, keys) => {
            const swrContent = new ropeEndings({ observer, data, keys })
            ropeEndingsPlaceholder.append(swrContent.render())
            swrContent.initSelectric()
        }

        const generateAddToCartForm = (observer, data) => {
            const cartFormSwrContent = new CartFormSWR({ observer, data }).render()
            cartFormSwrPlaceholder.append(cartFormSwrContent)
        }

        const initDataAttributeListener = () => {
            // Listen to 'data-selected-variant' after 'configure' button clicked
            // Preserve the original setAttribute function
            const originalSetAttribute = Element.prototype.setAttribute
            // Override the setAttribute function
            Element.prototype.setAttribute = function (name, value) {
                // Check if the attribute being modified is 'data-selected-variant'
                if (name === 'data-selected-variant') setSelectValuesById(value)
                // Call the original setAttribute function
                originalSetAttribute.call(this, name, value)
            }
        }

        const setProductInformationMaxHeight = () => {
            if (Util.viewport() === 'lg' || Util.viewport() === 'xl') {
                const rightColumnElementsHeight = $('.npp__title').outerHeight() + $('.npp__attributes-dropdowns').outerHeight()
                const leftColumnElementsHeight = $('.npp__swiper-gallery').outerHeight() + $('.npp__quick-links').outerHeight()
                const productInformationElementHeight = rightColumnElementsHeight - leftColumnElementsHeight - 25
                $('.npp__product-informations').height(productInformationElementHeight)
            }
        }

        const init = () => {
            initGroupedVariantsTableSearch(rawData.variants, rawData.groupBy)
            initVariantsTableToggle()
            generateDropdowns(keys, data.columns)
            generateEndings(swrObserver, data, keys)
            generateAddToCartForm(swrObserver, data)
            initDataAttributeListener()
            allSubVariants && selectFirstAvailableVariant()
            setProductInformationMaxHeight()
        }

        if (allSubVariants.length > 0) init()
    })
})(jQuery)
