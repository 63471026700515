import { onCTAButtonClick } from '../../gtm/introduction'
//
// Area - Introduction
//
;(($) => {
    $(() => {
        const module = $('.area-introduction')

        // Only continue if module is on page
        if (!module.length) return

        const ctaButton = module.find('.btn')
        ctaButton.on('click', (e) => onCTAButtonClick(e))
    })
})(jQuery)
