import CheckboxGroup from '../filterGroups/checkboxGroup'
import RadioGroup from '../filterGroups/radioGroup'
import RangeGroup from '../filterGroups/rangeGroup'

const ELEMENTS = {
    CheckboxGroup,
    RadioGroup,
    RangeGroup,
}
export default class ElementsFactory {
    static createInstance(data) {
        const fieldsArray = data.fields
        const hasFields = Array.isArray(fieldsArray) && fieldsArray.length ? true : false
        if (hasFields) {
            const ElementCreator = ELEMENTS[data.type]
            const element = ElementCreator ? new ElementCreator(data) : null
            return element
        }
    }
}
