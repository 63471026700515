;(($) => {
    $(() => {
        let module = $('.back-to-top.visible')

        // Only continue if module is on page
        if (!module.length) return

        $(window).on('scroll', function () {
            let topPos = $(this).scrollTop()
            if (topPos > 200) {
                module.fadeIn()
            } else {
                module.fadeOut()
            }
        })

        module.on('click', () => {
            $('html, body').animate(
                {
                    scrollTop: 0,
                },
                800,
            )
            return false
        })
    })
})(jQuery)
