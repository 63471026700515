//
// Area - faq block
//

;(($) => {
    $(() => {
        let module = $('.area-faq-block')

        // Only continue if module is on page
        if (!module.length) return

        const items = document.querySelectorAll('.accordion__button')

        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded')

            if (itemToggle === 'false') {
                this.setAttribute('aria-expanded', 'true')
            } else {
                this.setAttribute('aria-expanded', 'false')
            }
        }

        items.forEach((item) => item.addEventListener('click', toggleAccordion))
    })
})(jQuery)
