import FilterResults from './FilterResults'

const node = document.getElementById('products_table')
const isCustomProductPageActive = !!window.document.querySelector('.new-product-view.swr')

const generateResults = (data) => {
    if (!document.body.contains(node)) {
        return
    }
    node.textContent = ''
    data.options.isCustomProductPageActive = isCustomProductPageActive
    if (data.columns.length > 0) {
        new FilterResults(node, data.options.groupBy ? sortSubVariantsByStock(data) : data).render()
        initStockColumnReorder()
    } else {
        node.innerHTML = `<div class="col-12 text-center">${window.globals.trans('filter.no_results')}</div>`
    }
}

const sortSubVariantsByStock = (data) => {
    data.variants.forEach((variant) => {
        variant.subVariants.sort((a, b) => b.Stock - a.Stock)
    })
    return data
}

const initStockColumnReorder = () => {
    if (window.innerWidth < 992) {
        moveStockColumn()
    } else {
        window.onresize = () => {
            if (window.innerWidth < 992) {
                moveStockColumn()
            }
        }
    }
}

const moveStockColumn = () => {
    const stockCells = document.querySelectorAll('[data-key="Stock"]')
    stockCells.forEach((cell) => {
        if (!cell.parentNode.classList.contains('more-detail-item')) {
            cell.classList.remove('hide-on-mobile')
            const elems = cell.parentNode.childNodes
            const referenceNode = every_nth(Array.from(elems), 1)
            referenceNode.parentNode.insertBefore(cell, referenceNode.nextSibling)
        }
    })
}

const every_nth = (arr, n = 0) => (n > 0 ? arr.slice(n, n + 1) : arr.slice(n))[0]

export default generateResults
