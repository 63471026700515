import table from './resultsTable/table'
import bodiesFactory from './resultsTable/bodiesFactory/bodiesFactory'
import headersFactory from './resultsTable/headersFactory/headersFactory'
import Observer from '../../Observer/Observer'
import CartForm from './resultsTable/cartForm/cartForm'
import VARIANTS_TYPES from './config/variants-config'

const TRACEPART_CFG = {
    URL: 'https://www.traceparts.com/els/axinter/en/api/viewer/3d?',
    OPTIONS:
        '&DisplayUIScreenshotButtonMenu=false&DisplayUISettingsSubMenu=false&DisplayUIResetButtonMenu=false&DisplayUISelectionModeSubMenu=false&DisplayUIContextMenu=false',
}

export default class FilterResults {
    constructor(node, data) {
        this.data = data
        this.node = node
        this.observer = new Observer()
        this.$blueprintCarousel = $('.blueprint-carousel')
        this.table = new table({ id: 'variants_table' }).render()
        if ((this.data.options.isRfqActive || this.data.options.isWebshopActive) && !this.data.options.isCustomProductPageActive)
            this.cart = new CartForm({ data: this.data, observer: this.observer }).render()
    }

    getHeaders(thead) {
        return thead.querySelectorAll('.v-th.count')
    }

    removeKeyFromData(key) {
        // Hiding column key for Catalog
        if ('columns' in this.data) {
            Object.keys(this.data.columns).map((idx) => {
                if (this.data.columns[idx].key === key) {
                    delete this.data.columns[idx]
                }
            })
        }

        if ('subColumns' in this.data) {
            Object.keys(this.data.subColumns).map((idx) => {
                if (this.data.subColumns[idx].key === key) {
                    delete this.data.subColumns[idx]
                }
            })
        }
    }

    render() {
        let isActionWasFired = false
        const isCadCarouselImageAdded = $('.npp__swiper-gallery .swiper-cad-thumb').length > 0
        const options = this.data.options
        let type = ''
        if (!this.data.options.isCustomProductPageActive) {
            type = options.isGrouped ? VARIANTS_TYPES.SUBVARIANTS : VARIANTS_TYPES.GENERIC_VARIANTS
            if (options.isSwr && options.isWebshopActive && type === VARIANTS_TYPES.GENERIC_VARIANTS) {
                type = VARIANTS_TYPES.GENERIC_VARIANTS_SWR
            }
        } else {
            if (options.isSwr && options.isWebshopActive && options.isGrouped) {
                type = VARIANTS_TYPES.GENERIC_VARIANTS
            } else {
                console.log('You need to specify SWR group')
                return
            }
        }

        if (!options.isWebshopCategoryOnly) {
            this.removeKeyFromData('Stock')
            this.removeKeyFromData('Price')
        }
        const thead = new headersFactory(VARIANTS_TYPES.COLUMNS_HEADER, { data: this.data, type: type }).render()
        const tbody = new bodiesFactory(type, {
            data: this.data,
            type: type,
            observer: this.observer,
            table: this.table,
            headers: this.getHeaders(thead),
            cart: options.isCustomProductPageActive ? undefined : this.cart,
        }).render()

        this.table.appendChild(thead)
        this.table.appendChild(tbody)
        this.node.appendChild(this.table)
        if (this.data.options.isBlueprint) {
            this.initBlueprintCarousel(this.$blueprintCarousel)
        }
        this.setHeightsOfTableHeaders()
        this.observer.subscribe({
            event: 'enableCadColumn',
            action: (manufacturePartCode) => {
                if (!isActionWasFired) {
                    isActionWasFired = true
                    const cadCells = this.table.querySelectorAll('.cad-cell')
                    const cadHeader = this.table.querySelector('.header-cell[data-key="cad"]')
                    cadCells.forEach((cell) => {
                        const parent = cell.parentNode
                        if (parent.classList.contains('more-detail-item')) {
                            parent.classList.remove('hide-everywhere')
                        }
                        cadHeader.classList.remove('hide-everywhere')

                        cell.classList.remove('hide-everywhere')
                    })
                    if (!isCadCarouselImageAdded) this.inject3DImageToCarousel(manufacturePartCode)
                }
            },
        })
    }

    inject3DImageToCarousel(manufacturePartCode) {
        const productElem = document.querySelector('.new-product-card')
        const supplierCode = productElem.getAttribute('data-supplier-code')

        if (supplierCode && manufacturePartCode) {
            const isVerticalGallery = !!$('.swiper-gallery').length

            // HTML to inject
            const iframeHTML = `<div class="iframe-container">
            <iframe class="responsive-iframe" frameborder="0" loading="lazy" width="100%" src="${TRACEPART_CFG.URL}SupplierID=${supplierCode}&PartNumber=${manufacturePartCode}${TRACEPART_CFG.OPTIONS}"></iframe>
            </div>`

            if (isVerticalGallery) {
                // Swiper gallery
                // Add 3D Thumb
                $('<div class="swiper-slide swiper-cad-thumb"><div class="icon-cad available"></div></div>').appendTo(
                    '.swiper-gallery .swiper-thumbnails .swiper-wrapper',
                )
                // Add 3D Image
                $(`<div class="swiper-slide swiper-cad">${iframeHTML}</div>`).appendTo('.swiper-gallery .swiper-carousel .swiper-wrapper')
                $(`<div class="swiper-slide swiper-cad">${iframeHTML}</div>`).appendTo('.swiper-gallery .swiper-zoom .swiper-wrapper')
                // Swiper Vertical gallery
                document.querySelector('.swiper-carousel').swiper.updateSlides()
                document.querySelector('.swiper-thumbnails').swiper.updateSlides()
            } else {
                // OWL carousel gallery
                // Add 3D Thumb
                $('<div class="owl-thumb-item owl-cad"><div class="icon-cad available"></div></div>').appendTo('.npp__gallery .owl-thumbs')
                // Add 3D Image
                $('#media-carousel, #modal-carousel').trigger('add.owl.carousel', [iframeHTML]).trigger('refresh.owl.carousel')
            }
        } else {
            if (!supplierCode) {
                console.error(`No supplierCode: ${supplierCode}`)
            }
            if (!manufacturePartCode) {
                console.error(`No manufacturePartCode: ${manufacturePartCode}`)
            }
        }
    }

    setHeightsOfTableHeaders() {
        const tablesHeaders = document.querySelectorAll('.filter-results thead')
        let highestHeader = 0
        Array.from(tablesHeaders).map((header) => {
            const currentHeaderHeight = header.firstElementChild.offsetHeight
            if (currentHeaderHeight > highestHeader) {
                highestHeader = currentHeaderHeight
            }
        })
        Array.from(tablesHeaders).map((header) => {
            header.firstElementChild.style.height = `${highestHeader}px`
        })
    }

    initBlueprintCarousel(carousel) {
        carousel.owlCarousel({
            loop: false,
            margin: 10,
            items: 1,
            lazyLoad: true,
            autoHeight: false,
            nav: true,
            dots: false,
            navText: [
                '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#previous"></use></svg>',
                '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#next"></use></svg>',
            ],
        })
    }
}
