import axios from 'axios'
import { getUrlParams } from '../url-params/url-params'

const devMode = false

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
}

const PROTOCOL = window.location.protocol
const API_URL_PRODUCTS = `search/products`
const API_URL_ARTICLES = `search/articles`
const LOCALE = document.documentElement.lang || 'en'

let PRODUCTS_FULL_PATH = `/${LOCALE}/${API_URL_PRODUCTS}/data`
let PRODUCTS_INIT_FILTERS_FULL_PATH = `/${LOCALE}/${API_URL_PRODUCTS}/filters`
let ARTICLES_FULL_PATH = `/${LOCALE}/${API_URL_ARTICLES}`

axios.defaults.baseURL = `${PROTOCOL}//${window.location.hostname}`

if (devMode) {
    PRODUCTS_FULL_PATH = 'http://localhost:3333/mock/api/catalog-data'
    PRODUCTS_INIT_FILTERS_FULL_PATH = 'http://localhost:3333/mock/api/catalog-data/initial-filters'
    ARTICLES_FULL_PATH = 'http://localhost:3333/mock/api/search-articles'
}

const getQuery = () => {
    const dataFromUrl = getUrlParams()
    return dataFromUrl.query || ''
}

const getFilters = async () => {
    const query = getQuery()
    return await axios.get(PRODUCTS_INIT_FILTERS_FULL_PATH, {
        params: {
            query,
        },
    })
}

const getPage = async (page, filterData) => {
    const query = getQuery()
    return await axios.get(PRODUCTS_FULL_PATH, {
        params: {
            query,
            pageNo: page,
            ...filterData,
        },
    })
}

const postFilters = async (data) => {
    if (data.pageNo) {
        delete data.pageNo
    }
    const query = getQuery()
    return await axios.get(PRODUCTS_FULL_PATH, {
        params: Object.assign(
            {
                query,
            },
            data,
        ),
    })
}

const getArticles = async (pageNo = 1) => {
    const query = getQuery()
    const request = await axios.get(ARTICLES_FULL_PATH, {
        params: {
            query,
            pageNo,
        },
    })
    return request
}

export { getFilters, getPage, postFilters, getArticles }
